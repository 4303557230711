import React, { useEffect, useState } from 'react'
import sanityClient from '../sanityClient'
import { v4 as uuidv4 } from 'uuid'

const Footer = () => {
  const [visitorCount, setVisitorCount] = useState(0)
  const [location, setLocation] = useState({ city: null, country: null })

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0]

    // Check or create a unique user ID in localStorage
    let userId = localStorage.getItem('userId')
    if (!userId) {
      userId = uuidv4()
      localStorage.setItem('userId', userId)
    }

    const fetchVisitorData = async () => {
      const data = await sanityClient.fetch(`*[_type == "visitorCount"][0]`)

      if (data) {
        const { count, visits = [] } = data

        // Vérifiez si le visiteur a déjà été compté aujourd'hui
        // const lastStoredVisit = localStorage.getItem('lastVisitDate')
        // Check if this user has already visited today
        const hasVisitedToday = visits.some(
          (visit) => visit.userId === userId && visit.visitDate === today
        )

        if (!hasVisitedToday) {
          // Incrémenter le compteur dans Sanity si la dernière visite n'est pas aujourd'hui
          const updatedData = await sanityClient
            .patch(data._id)
            .setIfMissing({ visits: [] })
            .append('visits', [{ _key: uuidv4(), userId, visitDate: today }])
            .set({
              count: count + 1,
              location: {
                city: location.city,
                country: location.country,
              },
            })
            .commit()

          setVisitorCount(updatedData.count)
          localStorage.setItem('lastVisitDate', today)
        } else {
          setVisitorCount(count)
        }
      } else {
        // Créer un nouveau document si aucun document n'existe encore
        const newData = await sanityClient.create({
          _type: 'visitorCount',
          count: 1,
          visits: [{ _key: uuidv4(), userId, visitDate: today }],
          location: {
            city: location.city,
            country: location.country,
          },
        })
        setVisitorCount(newData.count)
        localStorage.setItem('lastVisitDate', today)
      }
    }

    const fetchLocation = async () => {
      try {
        const response = await fetch(
          `https://ipinfo.io?token=${process.env.REACT_APP_IPINFO_TOKEN}`
        )
        const data = await response.json()
        setLocation({ city: data.city, country: data.country_name })
      } catch (error) {
        return null
      }
    }

    fetchLocation().then(fetchVisitorData)
  }, [location.city, location.country])

  return (
    <>
      <div className='footer'>
        <div className='footer-box'>
          <div className='left' data-aos='fade-left'>
            <h4>Contactez-moi!</h4>
            <p>
              Vous pouvez me retrouver sur les plateformes sociales ci-dessous
              ou directement m'envoyer un mail à{' '}
              <a href='mailto:diamil.dev@gmail.com'>diamil.dev@gmail.com</a>
            </p>
            <div className='social-media'>
              <a
                href='https://www.linkedin.com/in/diamiltechdev/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='fab fa-linkedin-in'></i>
              </a>
              <a
                href='https://github.com/Diam-98'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='fab fa-github'></i>
              </a>
              {/* <a
                href="https://www.youtube.com/channel/UCQQ9kLuxj7DJELb3lqgQtdA"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-youtube"></i>
              </a> */}
            </div>
          </div>

          <div className='right' data-aos='fade-right'>
            <div className='footer-img'>
              <img
                src={process.env.PUBLIC_URL + '/my_profile.jpg'}
                alt='Mon Profile'
              />
            </div>
            <span className='name'>DIAM DIALLO</span>
            <span>Visiteur : {visitorCount}</span>
          </div>
        </div>
      </div>
      <div className='bottom'>
        <p className='text-center'>
          &copy; 2022-2024, Diam Diallo. Tous droits réservés
        </p>
      </div>
    </>
  )
}

export default Footer
